.loginImgStyle {height: 100vh; object-fit: cover; width: 100%;     display: block;}
.authRight{background: var(--white); display: grid; place-items: center; height: 100%; min-height: 100vh; overflow: auto;}
.authRight .loginInner{width: 100%; padding: 0 50px;}
.logobx{display: grid; place-items: flex-start;}
.logobx a.navbar-brand{min-width: 200px; margin-left: 0px;}
.navbar-brand img{width: 43px !important;}
.loginLogo{font-size: 27px !important; font-weight: 500 !important;  left: 100px !important;}
.logohead {font-size: 35px; font-weight: 400; color: var(--black);}
.loginpanal h1{font-size: var(--font35); color: var(--black); font-weight: 800;}
.loginpanal p{font-size: var(--font14); font-weight: 300;}
.loginimp{background-color: var(--gray) !important;padding: 13px !important; border: none !important; padding-left: 45px !important;}
.loginimp::placeholder{color:var(--darkgray1) !important}
.login_forgot a{font-size: var(--font16); font-weight: 500; color: var(--blue); text-decoration: none;}
