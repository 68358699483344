:root {
  --black: #000;
  --white: #ffffff;
  --gray: #F0F0F0;
  --blue: #0088D5;
  --blue1: #0BB4C5;
  --blue2: #007DC2;
  --darkgray1: #5F5F5F;
  --darkgray2: #263238;
  --gray1: #636363;
  --gray2: #9A9A9A;
  --gray3: #AFC4C0;
  --gray4: #eeeeee;
  --gray5: #D9D9D9;
  --lightGreaan: #F4F7FE;
  --lightGreen2: #E0E9FD;

  --lightGray: #C8C8C8;
  --menuwidth: 350px;
  --menuSmall: 80px;

  --red: #F20202;
  --green: #0FBA53;
  --red2: #D30000;
  --yellow: #CA9307;
  --success: #23BF4A;
  --danger: #DD0000;

}
body{
  font-family: "Poppins";
}

@media only screen and (max-width:1920px) {
  :root {
    --font14: 16px;
    --font16: 18px;

    --font18: 20px;
    --font20: 22px;
    --font22: 24px;
    --font24: 26px;
    --font26: 28px;
    --font28: 30px;
    --font35: 37px;



  }
}

@media only screen and (max-width:1600px) {
  :root {
    --font14: 14px;
    --font16: 16px;

    --font18: 18px;
    --font20: 20px;
    --font22: 22px;
    --font24: 24px;
    --font26: 26px;
    --font28: 28px;
    --font35: 35px;


  }
}



/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 5px;
  border-radius: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border: 2px solid #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border: 2px solid #fff;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.adminLayoutMaine {
  width: 100%;
  height: 100vh;
  background-color: var(--white);
}

.leftMenu {
  width: var(--menuSmall);
  height: 100vh;
  background: linear-gradient(#FFFFFF, #FFFFFF, #EAF8FF);
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 5px 4px 22px #00000024;
  transition: all .3s ease-in-out;
  z-index: 1;
}

.logobx {
  display: grid;
  place-items: flex-start;
  padding: 20px 15px;
  min-height: 100px;
  align-items: center;
  border-bottom: 0.5px solid var(--gray1);
}

.logobx a.navbar-brand {
  min-width: 200px;
  margin-left: 0px;
}
.anticon svg{-webkit-transform: scaleY(-1); 
   transform: scaleY(-1);
  }
.logobox {
  width: 3.5rem;
  overflow: hidden;
  transition: all .3s ease-in-out;
}

.logobox img {
  width: 6rem !important;
  transition: all .3s ease-in-out;
}

.loginLogo {
  font-size: 27px !important;
  font-weight: 500 !important;
  left: 100px !important;
}

.logohead {
  font-size: var(--font24);
  font-weight: 400;
  color: var(--black);
}

.rightBox {
  width: calc(100% - var(--menuSmall));
  background-color: var(--white);
  height: 100%;
  margin-left: var(--menuSmall);
  padding: 20px;
  overflow: auto;
  transition: all .3s ease-in-out;
}

.leftMenu:hover .logobox,
.leftMenu.active .logobox {
  width: 13rem;
}

.leftMenu:hover .logobox img,
.leftMenu.active .logobox img {
  width: 13rem !important;
}

.leftMenu:hover,
.leftMenu.active {
  width: var(--menuwidth);
}

.leftMenu:hover+.rightBox,
.leftMenu.active+.rightBox {
  width: calc(100% - 350px);
  margin-left: var(--menuwidth);
}

.id-btn{height:1.8rem !important}
.adminLayoutMaine.active .leftMenu {
  width: 75px;
}

.adminLayoutMaine.active .rightBox {
  width: calc(100% - 75px);
  margin-left: 75px
}

.adminLayoutMaine.active .leftMenu .logohead {
  transition: all .3s ease-in-out;
}


.text-success {
  color: var(--green) !important;
}

.text-danger {
  color: var(--danger);
}

.h-min-content {
  height: min-content;
}

.btn {
  height: 2.9375rem;
  padding: 0px 7px;
  font-size: var(--font16) !important;
  font-weight: 600 !important;
}

.btn-primary-v {
  background: linear-gradient(0deg, #1365A7, #5EB1DA);
  border: 0;
}
/* #search-bar-0{width:41.875rem !important} */
:where(.css-dev-only-do-not-override-qnu6hi).ant-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 9px 11px 4px !important;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  position: relative;
  display: inline-flex;
  align-items: center;
  border-radius: 6px;
  transition: border 0.2s, box-shadow 0.2s, background 0.2s;
}
.ant-picker-range .ant-picker-suffix .anticon{
  display: none !important;
}
.btn-primary-v:hover {
  background: linear-gradient(180deg, #1365A7, #5EB1DA);
}

.btn-primary-h {
  background: linear-gradient(280deg, #007DC2, #0EC6C6) !important;
  border: 0;
}

.btn-danger {
  background-color: var(--red);
}

.btn-dark {
  background-color: var(--darkgray2);
  font-weight: 700;
}

.form-control {
  border-radius: 14px;
}

.divtext {
  border: ridge 2px;
  padding: 5px;
  width: 20em;
  min-height: 5em;
  overflow: auto;
}

textarea.form-control:read-only {
  resize: none;
}

.logoutbox {
  width: calc(100% + 30px);
  border-top: .5px solid var(--gray1);
  /* padding: 30px 40px; */
  padding: 30px 40px 43px 40px;
  margin-left: -30px;
}

.logoutbox a {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
  text-decoration: none;
  font-size: var(--font22);
  font-weight: 700;
  margin-bottom: 1.3rem !important;
  color: var(--red);
  background-color: var(--white);
  padding: 10px;
  border-radius: 30px;
  width: 59px;
  padding-right: 20px;
}

.logoutbox a i {
  height: 40px;
  aspect-ratio: 1/1;
  background: var(--red);
  border-radius: 30px;
  display: inline-grid;
  place-items: center;
  font-size: 22px;
  color: var(--white);
  transition: all .3s ease-in-out;
}

.logoutbox a:hover i {
  background-color: var(--red);
}

.logoutbox a span {
  padding-bottom: 5px;
  visibility: hidden;
  opacity: 0;
  transition: all .3s ease-in-out;
}

.leftMenu:hover .logoutbox a span,
.leftMenu.active .logoutbox a span {
  padding-bottom: 5px;
  visibility: visible;
  opacity: 1;
}

.verifybtn {
  background: linear-gradient(90deg, #D99BFF96, #90CAF9);
  border-image-source: linear-gradient(90deg, #D99BFF96, #90CAF9, #F4F7FE);
  border: 1px solid;
  border-image-slice: 1;
}

.verifybtn:hover {
  border-image-source: linear-gradient(90deg, #D99BFF96, #90CAF9, #F4F7FE);
  background-size: 200%;
  border-width: 1px;
  animation: bg 1s ease infinite;
  border: 1px solid;
  border-image-slice: 1;
  border-width: 1px;
  color: var(--black);
}

.btn-outline-danger:hover {
  color: var(--white) !important;
}

@keyframes bg {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.logobx {
  display: grid;
  place-items: flex-start;
}

.logobx a.navbar-brand {
  min-width: 50px;
  margin-left: 0px;
  transition: all .3s ease-in-out;
}

.leftMenu:hover .logobx a.navbar-brand,
.leftMenu.active .logobx a.navbar-brand {
  min-width: 200px;
}

.loginLogo {
  font-size: 27px !important;
  font-weight: 500 !important;
  left: 100px !important;
}

.logohead {
  font-size: 35px;
  font-weight: 400;
  color: var(--black);
  display: none;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease-in-out;
}

.leftMenu:hover .logohead,
.leftMenu.active .logohead {
  opacity: 1;
  visibility: visible;
  display: block;
}

.menuBox {
  width: 100%;
  height: calc(100vh - 100px);
  margin-top: 0px;
  padding-left: 0;
  transition: all .3s ease-in-out;
  display: flex;
  padding-top: 0;
  flex-direction: column;
}

.menubutton {
  font-size: var(--font24) !important;
}

/* .menuBox:hover{overflow: auto;} */
/* .menuBox:hover{overflow: auto;} */

.simplebar-content-wrapper {
  overflow: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.simplebar-content-wrapper::-webkit-scrollbar {
  display: none;
}

.menuBox ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 30px;
}

.menuBox ul li {
  margin-bottom: 8px;
}

.menuBox ul li a {
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  position: relative;
  color: var(--gray1);
  font-size: var(--font16);
  text-decoration: none;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 400;
}

.menuBox ul li a i {
  font-size: 26px;
  padding: 10px;
  transition: all .3s ease-in-out;
  border-radius: 7px;
}

.menuBox ul li a span {
  white-space: pre;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease-in-out;
  margin-left: -15px;
}

.leftMenu:hover .menuBox ul li a span,
.leftMenu.active .menuBox ul li a span {
  white-space: pre;
  opacity: 1;
  visibility: visible;
  margin-left: 0px;
}

.menuBox ul li a.active i,
.menuBox ul li a:hover i {
  color: var(--white);
}

/* .menuBox ul li a::before{content: ''; width: 6px; border-radius: 6px; height: 100%; background-color: none; position: absolute; top: 0; right: 0; transition: all .3s ease-in-out; z-index: -1;} */
.menuBox ul li a:hover,
.menuBox ul li a.active {
  background: var(--blue);
  color: var(--white);
  font-weight: 400;
}

.menuBox ul li a:hover::before,
.menuBox ul li a.active::before {
  background-color: var(--blue);
}

.menuBox ul li {
  position: relative;
}

.menuBox ul li a:hover+ul.submenu,
.menuBox ul li a.active+ul.submenu {
  background: var(--blue);
}

.menuBox ul li:hover,
.menuBox ul li:hover ul.submenu {
  background: var(--blue);
}

.menuBox ul li:hover a {
  color: var(--white);
}

.menuBox ul li ul.submenu {
  background-color: none;
  padding: 10px;
  height: 0;
  padding-left: 60px;
  margin-bottom: 0;
  visibility: 0;
  opacity: 0;
  overflow: hidden;
  transition: all .3s ease-in-out;
}

.menuBox ul li ul.submenu li a {
  font-size: var(--font14);
  transition: all .3s ease-in-out;
  color: var(--white);
}

.menuBox ul li ul.submenu li a i {
  padding: 0;
  font-size: var(--font14);
}

.menuBox ul li ul.submenu li a:hover,
.menuBox ul li ul.submenu li a.active {
  color: var(--white);
  background: none;
  font-weight: 600;
}

.menuBox ul li ul.submenu li a::before {
  display: none;
}

.menuBox ul li:hover ul.submenu li:hover a {
  background: none !important;
}

.leftMenu:hover .menuBox ul li:hover ul.submenu,
.leftMenu:hover .menuBox ul li a.active+ul.submenu,
.leftMenu.active .menuBox ul li a.active+ul.submenu {
  height: auto;
  visibility: visible;
  opacity: 1;
}

.childmenu {
  padding-left: 10px !important;
  height: 0;
  margin: 0 !important;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease-in-out;
}

.menuBox ul li ul.submenu li:hover .childmenu {
  height: auto;
  visibility: visible;
  opacity: 1;
}

.childmenu li a i {
  font-size: 15px !important;
  padding: 0 !important;
}

.iconBox {
  width: 46px;
  height: 46px;
  transition: all .3s ease-in-out;
  padding: 10px;
  transition: all .3s ease-in-out;
  border-radius: 7px;
}

.menuBox ul li a:hover .iconBox,
.menuBox ul li a.active .iconBox {
  background-color: var(--blue);
  padding: 10px;
  border-radius: 7px;
  color: var(--white);
}

.dashboard {
  background: url('../images/svg/icons/dashboard.svg') no-repeat;
  background-position: -34px 0;
  background-size: 64px 25px;
}

.menuBox ul li a:hover .dashboard,
.menuBox ul li a.active .dashboard {
  background-position: -2px 0;
}

.manageuser {
  background: url('../images/svg/icons/manageUser.svg') no-repeat;
  background-position: -33px 0;
  background-size: 60px 25px;
}

.menuBox ul li a:hover .manageuser,
.menuBox ul li a.active .manageuser {
  background-position: 0px 0;
}

.artapproval {
  background: url('../images/svg/icons/manageArt.svg') no-repeat;
  background-position: -34px 0;
  background-size: 64px 25px;
}

.menuBox ul li a:hover .artapproval,
.menuBox ul li a.active .artapproval {
  background-position: -3px 0;
}

.collections {
  background: url('../images/svg/icons/collection.svg') no-repeat;
  background-position: -34px 0;
  background-size: 64px 25px;
}

.menuBox ul li a:hover .collections,
.menuBox ul li a.active .collections {
  background-position: -3px 0;
}

.sendmail {
  background: url('../images/svg/icons/sendMail.svg') no-repeat;
  background-position: -34px 0;
  background-size: 64px 25px;
}

.menuBox ul li a:hover .sendmail,
.menuBox ul li a.active .sendmail {
  background-position: -3px 0;
}

.items {
  background: url('../images/svg/icons/items.svg') no-repeat;
  background-position: -34px 0;
  background-size: 64px 25px;
}

.menuBox ul li a:hover .items,
.menuBox ul li a.active .items {
  background-position: -3px 0;
}

.category {
  background: url('../images/svg/icons/category.svg') no-repeat;
  background-position: -34px 0;
  background-size: 64px 25px;
}

.menuBox ul li a:hover .category,
.menuBox ul li a.active .category {
  background-position: -3px 0;
}

.addblog {
  background: url('../images/svg/icons/addblog.svg') no-repeat;
  background-position: -34px 0;
  background-size: 64px 25px;
}

.menuBox ul li a:hover .addblog,
.menuBox ul li a.active .addblog {
  background-position: -3px 0;
}

.activity {
  background: url('../images/svg/icons/activity.svg') no-repeat;
  background-position: -34px 0;
  background-size: 64px 25px;
}

.menuBox ul li a:hover .activity,
.menuBox ul li a.active .activity {
  background-position: -3px 0;
}

.transection {
  background: url('../images/svg/icons/transection.svg') no-repeat;
  background-position: -34px 0;
  background-size: 64px 25px;
}

.menuBox ul li a:hover .transection,
.menuBox ul li a.active .transection {
  background-position: -3px 0;
}

.contectpage {
  background: url('../images/svg/icons/contentpage.svg') no-repeat;
  background-position: -34px 0;
  background-size: 64px 25px;
}

.menuBox ul li a:hover .contectpage,
.menuBox ul li a.active .contectpage {
  background-position: -3px 0;
}

.faq {
  background: url('../images/svg/icons/fqa.svg') no-repeat;
  background-position: -34px 0;
  background-size: 64px 25px;
}

.menuBox ul li a:hover .faq,
.menuBox ul li a.active .faq {
  background-position: -3px 0;
}

.gensett {
  background: url('../images/svg/icons/setting.svg') no-repeat;
  background-position: -34px 0;
  background-size: 64px 25px;
}

.menuBox ul li a:hover .gensett,
.menuBox ul li a.active .gensett {
  background-position: -3px 0;
}

.imgsett {
  background: url('../images/svg/icons/imageSetting.svg') no-repeat;
  background-position: -34px 0;
  background-size: 64px 25px;
}

.menuBox ul li a:hover .imgsett,
.menuBox ul li a.active .imgsett {
  background-position: -3px 0;
}

.blocknet {
  background: url('../images/svg/icons/blockchainnetwork.svg') no-repeat;
  background-position: -34px 0;
  background-size: 64px 25px;
}

.menuBox ul li a:hover .blocknet,
.menuBox ul li a.active .blocknet {
  background-position: -3px 0;
}

.wallet {
  background: url('../images/svg/icons/wallet.svg') no-repeat;
  background-position: -34px 0;
  background-size: 64px 25px;
}

.menuBox ul li a:hover .wallet,
.menuBox ul li a.active .wallet {
  background-position: -3px 0;
}

.breadcrumbnew {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 20px;
}

.breadcrumbnew li:nth-child(1) {
  padding-left: 0;
}

.breadcrumbnew li {
  padding-left: 30px;
}

.breadcrumbnew li,
.breadcrumbnew li a {
  font-size: var(--font24);
  font-weight: 400;
  color: var(--darkgray2);
  text-decoration: none;
}

.breadcrumb-item+.breadcrumb-item::before {
  content: '\f105';
  font-family: "Font Awesome 6 Pro";
  translate: -75% 7px;
  font-size: 18px;
}

.breadcrumbnew li:nth-last-child(1) {
  font-size: var(--font24);
  font-weight: 800;
  color: var(--black);
}

.breadcrumbnew li.active:nth-last-child(1) a {
  font-size: var(--font24);
  font-weight: 800;
  color: var(--black);
}

.topprofile {
  padding: 10px;
  padding-bottom: 7px;
  box-shadow: 0 2px 12px 0 #00000012;
  border-radius: 40px;
}

.topprofile ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding-right: 5px;
}

.topprofile ul li button {
  width: 40px;
  aspect-ratio: 1/1;
  display: grid;
  place-items: center;
  outline: none;
  border: none;
  background: none;
}

.topprofile ul li button.profileIcon {
  width: auto;
  height: auto;
  padding: 0;
  border-radius: 40px;
  background-color: #D9D9D9;
}

/* -----------------------filter ----------------------------- */
.filterBox {
  margin-top: 40px;
}

.filterBox h2 {
  font-size: var(--font20);
  font-weight: 700;
  color: var(--black);
  margin-bottom: 15px;
}

.filterBox .form-control {
  box-shadow: 0 0 8px #00000012;
  border-radius: 28px;
  border: 0;
  padding: 10px 45px 10px 20px !important;
}

.filterBox .form-control+button {
  color: var(--lightGray);
}

.filterBox .form-control::placeholder {
  color: var(--gray1) !important;
}

.filterBox h3 {
  font-size: var(--font18);
  font-weight: 700;
  color: var(--gray1);
  margin-bottom: 0;
  display: flex;
  white-space: nowrap;
}

.filterBox .clearAll {
  background-color: var(--red);
  border-radius: 30px;
  padding: 7px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--white);
  box-shadow: 0 0 9px 0 #0000001a, inset 2px 1px 7px 0 #0000001a;
}


/* -----------------------table ------------------------ */
.tableBox {
  width: 100%;
}

.tableBox table {
  width: 100%;
}

.tableBox table thead tr {
  border-top: 2px solid var(--gray3);
  border-bottom: 2px solid var(--gray3);
}

.tableBox table thead tr th,
.tableBox table thead tr td {
  color: var(--gray2);
  font-weight: 700;
  white-space: pre;
}

.tableBox table tr td {
  padding: 10px 10px;
  font-size: var(--font14);
  color: var(--black);
  font-weight: 400;
  border: 0;
  white-space: nowrap;
}

.tableBox table tbody tr {
  border-bottom: 1px solid var(--gray4);
}

.tableBox table tbody tr:nth-last-child(1) {
  border: 0;
}

/* .usertable table tr td{width: 22.5%; } */
.usertable table tr td:nth-child(1) {
  width: 20%;
}

.usertable table tr td:nth-last-child(1),
.usertable table tr th:nth-last-child(1),
.tableBox table tr td:nth-last-child(1),
.tableBox table tr th:nth-last-child(1)  {
  width: 6%;
  text-align: left;
}

.arttble table tr td {
  width: auto;
}

/* .verifytable table tr td{width: 18%; } */
.verifytable table tr td:nth-last-child(1),
.verifytable table tr th:nth-last-child(1),
.categorytable table tr th:nth-last-child(1),
.categorytable table tr td:nth-last-child(1),
.sendmail table tr td:nth-last-child(1),
.sendmail table tr th:nth-last-child(1),
.nftlist table tr td:nth-last-child(1),
.nftlist table tr th:nth-last-child(1) {
  width: 6%;
  text-align: left;
}

/* .sendmail table tr td:nth-child(1),   .sendmail table tr th:nth-child(1){width: 5%; text-align: center;} */

.collectionList table tr td:nth-last-child(1),
.collectionList table tr th:nth-last-child(1) {
  width: 6%;
  text-align: left;
}
.react-bs-table-no-data{text-align: center !important;}
.collectionList table tr td:nth-child(4),
.collectionList table tr th:nth-child(4) {
  text-align: center;
}

.categorytable table tr th {
  width: 30%;
}

/* .tableBox table tbody tr td .verify,   .tableBox table tbody tr td .artist, .tableBox table tbody tr td .artist{color: var(--green);} */
.tableBox table tbody tr td div:is(.active, .artist, .verify) {
  color: var(--green);
}

.tableBox table tbody tr td .pending {
  color: var(--yellow);
}

.tableBox table tbody tr td div:is(.reject, .inactive) {
  color: var(--red2);
}

.tableBox table tbody tr td .museums {
  color: var(--blue);
}

.faqbtn {
  padding: 5px 10px;
  border-radius: 5px;
  width: 70px;
  text-align: center;
  text-transform: capitalize;
  font-weight: 500;
}

.faqbtn.buyer {
  background-color: #FFE9FD;
  color: #B826AB;
}

.faqbtn.artist {
  background-color: #F2FFFF;
  color: #0EC6C6;
}

.faqtble table tr td:nth-last-child(1),
.faqtble table tr th:nth-last-child(1) {
  text-align: right;

}
.range-date{width:18rem !important}
.react-bs-table-no-data {
  font-size: 24px !important;
  font-weight: 600 !important;
  padding: 30px 0 !important;
  text-transform: capitalize;
  text-align: center;
}

/* -------------------collection ------------------------- */
.CollectionnftBox {
  width: 100%;
  margin-bottom: 15px;
}

.CollectionnftBox figure {
  width: 100%;
  padding: 15px;
  padding-bottom: 5px;
  box-shadow: 0 2px 9px #0000002B;
  margin-bottom: 0;
}

.CollectionnftBox figure img {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.CollectionnftBox figure figcaption {
  font-weight: 700;
  font-size: var(--font18);
  color: var(--black);
}

/* ----------------------dialog ----------------- */
.dialogMain {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  background-color: #000000c5;
  backdrop-filter: blur(5px);
  display: grid;
  place-items: center;
  display: none;
}

.dialogInner {
  width: min(60%, 90%);
  background: #fff;
  border-radius: 13px
}

.viewBlog .dialogInner {
  width: min(80%, 90%);
}

.dialogMain.show {
  display: grid;
}

.dialogInner .dialogHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  border-bottom: #000 solid 1px;
  border-radius: 11px 12px 0px 0px;

}

.dialogInner .dialogHead h1 {
  font-size: var(--font24);
  font-weight: 700;
  margin: 0;
}

.dialogInner .closebtn {
  background: none;
  border: 0;
  outline: none;
}

.dialogInner .dialogbody {
  padding: 20px 30px;
  max-height: 79vh;
  overflow: auto;
  margin-bottom: 25px;
}

.artbtns button {
  padding-left: 100px;
  padding-right: 100px;
}

.toast-success {
  background-color: green !important;
}

.toast-error {
  background-color: red !important;
}

.spinnerBoxNew {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background-color: #ffffff36;
  backdrop-filter: blur(5px);
  display: grid;
  place-items: center;
}

.viewBlogBody img {
  aspect-ratio: 1/.1;
  object-fit: cover;
}

.viewBlogBody h1 {
  font-size: var(--font22);
  color: var(--gray1);
  font-weight: 700;
  margin-top: 60px;
  margin-bottom: 25px;
}

.viewBlogBody p {
  font-size: var(--font16);
  color: var(--gray1);
  font-weight: 400;
}

.updatefile {
  width: 100%;
  background-color: var(--lightGreaan) !important;
  min-height: 250px;
  padding: 1.3rem;
  border-radius: 5px;
  display: grid;
  place-items: center;
}

.updatefile label {
  background: var(--blue);
  border-radius: 5px;
  padding: 10px 30px 13px;
  color: #fff;
  font-size: 16px;
}

.imguploaded {
  max-width: 300px;
  position: relative;
}

.imguploaded img {
  max-height: 280px;
  max-width: 100%;
  object-fit: contain;
}

.imguploaded button {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--red);
  font-size: 16px;
  background: none;
  border: 0;
  outline: none;
}

/* ---------------------- category add Box ----------------------------- */
.addbox {
  width: 100%;
  box-shadow: 0px 4px 14px 7px #0000000F;
  border-radius: 5px;
  margin-top: 40px;
  padding: 20px;
  margin-top: 200px;
}

.subcategoryList {
  width: 100%;
}

.subcategoryList ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}

.subcategoryList ul li {
  background-color: var(--lightGreaan) !important;
  border-radius: 5px;
  border: 0;
  padding: 12px 20px;
  color: var(--gray1);
  font-size: var(--font14);
  font-weight: 500;
  margin-bottom: 15px;
  position: relative;
}

.subcategoryList ul li button {
  background: var(--red);
  width: 18px;
  height: 18px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 15px;
  padding: 0px 5px;
  border: 0;
  outline: 0;
  display: grid;
  font-size: 13px;
  translate: 5px -5px;
  place-items: center;
  color: var(--white);
  cursor: pointer;
}

.uploadImageMaine {
  position: relative;
  margin-bottom: 75px;
}

.uploadImgNew {
  top: -100px;
  position: absolute;
  left: 40px;
  top: -175px;
  width: 200px;
  aspect-ratio: 1/1;
}

.uploadImgNew img {
  height: 100%;
  object-fit: contain;
  background: #E0E9FD;
}

.categoryUploadImage,
.categoryUploadImage input {
  background-color: var(--lightGreen2);
  position: relative;
  width: 100%;
  height: 100%;
}

.categoryUploadImage .edit {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  aspect-ratio: 1/1;
  border-radius: 30px;
  background-color: var(--white);
  display: grid;
  place-items: center;
}

.addblog {
  margin-top: 40px;
}

.addblog .catdropzone-single-image {
  aspect-ratio: 1/.2;
  position: relative;
}

.addblog .catdropzone-single-image img {
  height: 100%;
}

.blogUpload {
  width: 100%;
  cursor: pointer;
  height: 100%;
  background-color: #E0E9FD;
  aspect-ratio: 1/.2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.blogUpload h3 {
  font-size: var(--font16);
  font-weight: 600;
  color: var(--gray1);
}

.addblog .dropzone-single-image .edit {
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  color: var(--green);
  background: var(--white);
  border-radius: 40px;
  position: absolute;
  right: 30px;
  top: 30px
}

.blogTbs {
  background: #E0E9FD;
  margin-top: 20px;
  border-radius: 5px;
  gap: 5px;
  padding: 10px;
  display: flex;
  align-items: center;
  width: fit-content;
}

.blogTbs button {
  background: none;
  color: #000;
  border-radius: 3px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  border: none;
  outline: none;
  padding: 7px 25px;
  transition: all .3s ease-in-out;
}

.blogTbs button:hover,
.blogTbs button.active {
  background-color: #fff;
}

/* --------------------------notificationBox  ----------------------------*/
.notificationBox {
  width: 390px;
  position: fixed;
  top: 0;
  right: -390px;
  height: 100vh;
  background-color: var(--white);
  box-shadow: 0 4px 9px #0000003a;
  z-index: 10;
  transition: all .3s ease-in-out;
  border-radius: 10px 0 0 10px;
}

.notificationBox.active {
  right: 0;
}

.notificationBox .dialogHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px;
}

.notificationBox .dialogHead h1 {
  font-size: var(--font24);
  font-weight: 700;
  margin: 0;
}

.notificationBox .dialogHead .closebtn {
  background: none;
  border: 0;
  outline: none;
}

.notificationBox .notificationBody {
  width: 100%;
  height: calc(100vh - 82px);
  overflow: auto;
  overflow-x: hidden;
  padding: 0 20px;
}

.notificationBody ul {
  list-style: none;
  margin: 0;
  padding: 7px 0;
}

.notificationBody ul li {
  width: 100%;
  height: 100px;
  padding: 10px;
  margin-bottom: 15px;
  box-shadow: 0 0 7px #0000002b;
  border-radius: 5px;
}

.notificationBody ul li h2 {
  font-size: var(--font16);
  color: var(--blue);
  font-weight: 700;
}

.notificationBody ul li h2 span {
  color: var(--gray2);
  font-weight: 400;
  font-size: var(--font14);
  display: flex;
  gap: 5px;
}

.notificationBody ul li p {
  font-size: var(--font14);
  color: var(--black);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.notificationBox.active .notificationBody ul li {
  animation: delaynotification;
}

.notiificationCount {
  width: 20px;
  aspect-ratio: 1/1;
  background-color: var(--green);
  color: white;
  border-radius: 15px;
  font-size: 12px;
  display: grid;
  place-items: center;
  position: absolute;
  right: 0px;
  top: 0;
}

.unreadbox {
  color: var(--green) !important;
  font-size: 20px;

}

@keyframes delaynotification {
  0% {
    opacity: 0;
    transform: translate(30px);
  }

  80% {
    opacity: 0;
    transform: translate(30px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

/* ----------------------------- image setting -------------------------- */
.imgageBox {
  width: 100%;
  position: relative;
  border-radius: 7px;
  overflow: hidden;
}

.imgageBox button {
  border-radius: 25px;
  font-size: 20px;
  padding: 3px 3px;
  color: var(--white);
  background-color: red;
  border: 0;
  display: grid;
  place-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
  visibility: hidden;
  opacity: 0;
  transition: all .3s ease-in-out;
}

.imgageBox img {
  width: 100%;
  aspect-ratio: 1/.6;
  transition: all .3s ease-in-out;
}

.imgageBox img:hover {
  transform: scale(1.2);
}

.imgageBox:hover button {
  visibility: visible;
  opacity: 1;
}

.uploadImgBtn {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.uploadbtnImage {
  background: var(--lightGreen2);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.uploadbtnImage img {
  width: 70px;
}

.uploadbtnImage h4 {
  font-size: var(--font16);
  color: var(--gray1);
  font-weight: 500;
  margin-bottom: 0;
}



/* ---------------------blockchain network 2 ----------------------- */
.blockchainmainebOx {
  display: flex;
  align-items: center;
  gap: 15px;
}

.blockchainmainebOx h5 {
  font-size: var(--font18);
  font-weight: 700;
  color: var(--black);
}

.customeslideBox {
  background: var(--darkgray2);
  border-radius: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 12px;
}

.customeslideBox input {
  display: none;
}

.customeslideBox label {
  width: 50px;
  margin: 0;
  height: 31px;
  cursor: pointer;
  background: #E9E9EA;
  border-radius: 30px;
  position: relative;
  transition: all .3s ease-in-out;
}

.customeslideBox label::before {
  content: '';
  width: 25px;
  height: 25px;
  background-color: #fff;
  border-radius: 25px;
  position: absolute;
  top: 2.5px;
  left: 3px;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.12);
  transition: all .3s ease-in-out;
}

.customeslideBox input[type="checkbox"]:checked+label {
  background-color: #65C466;
}

.customeslideBox input[type="checkbox"]:checked+label::before {
  left: calc(100% - 29px);
}

.customeslideBox p {
  margin: 0;
  color: var(--white);
  font-weight: 500;
}

.networkTitle {
  font-size: var(--font16);
  color: var(--black);
  font-weight: 600;
}

.deletebtn {
  font-size: var(--font20) !important;
  padding: 0 10px;
}

.w-fitcontent {
  width: fit-content;
}

.uploadlogogbox {
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
}

.widthimg {
  aspect-ratio: 1/.3 !important;
}

.widthimg img {
  height: 100%;
}

.faqimgs {
  aspect-ratio: inherit;
}

.faqimage {
  max-height: 470px;
  overflow: hidden;
  border-radius: 7px;
}

.faqimage img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}


/* ----------------- Manage Earning ------------------------- */
.mt40 {
  margin-top: 40px;
}

.earningBox {
  width: 100%;
  padding: 20px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.07);
}

.earningBox img {
  object-fit: contain;
}

.earningBox h2 {
  color: #636363;
  font-size: var(--font18);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  margin-top: 15px;
}

.earningBox h3 {
  color: #636363;
  font-size: var(--font24);
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin: 0;
}

.earningBox.earn :is(h2, h3) {
  color: #007DC2;
}


.exportTab {
  display: flex;
  align-items: center;
  gap: 10px;
}

.exportTab div {
  /* background: #F4F7FE; */
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
  border-radius: 5px;
}

.exportTab div h2 {
  color: #000;
  font-size: var(--font20);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.exportbtn {
  display: flex;
  align-items: center;
  background: #263238;
  gap: 5px;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  color: #FFF;
  font-size: var(--font20);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: all .3s ease-in-out;
  cursor: pointer;
  border: 0;
  outline: 0;
}

.exportbtn:hover {
  background: #161f24;
}

.aspectration {
  aspect-ratio: auto !important;
}



.socialMediaArtist {
  display: flex;
  align-items: center;
  gap: 10px;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 10;
}

.socialMediaArtist li a div {
  transition: all .3s ease-in-out;
}

.socialMediaArtist li .facebook {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background: url('../images/svg/facebookArtist.svg') no-repeat;
  background-size: 50px;
}

.socialMediaArtist li .facebook:hover {
  background-position: -0px -60px;
}

.socialMediaArtist li .instagram {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background: url('../images/svg/instaArtist.svg') no-repeat;
  background-size: 50px;
}

.socialMediaArtist li .instagram:hover {
  background-position: -0px -60px;
}

.socialMediaArtist li .telegram {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background: url('../images/svg/telegramArtist.svg') no-repeat;
  background-size: 50px;
}

.socialMediaArtist li .telegram:hover {
  background-position: -0px -60px;
}

.socialMediaArtist li .youtube {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background: url('../images/svg/youtubeArtist.svg') no-repeat;
  background-size: 50px;
}

.socialMediaArtist li .youtube:hover {
  background-position: -0px -60px;
}

.socialMediaArtist li .discord {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background: url('../images/svg/discordArtist.svg') no-repeat;
  background-size: 50px;
}

.socialMediaArtist li .discord:hover {
  background-position: -0px -60px;
}

.togglebtn {
  width: 35px;
  height: 35px;
  line-height: 20px;
  position: absolute;
  left: 100%;
  top: 35px;
  border-radius: 0 3px 3px 0 !important;
}

.box1 {
  /* width: 415px; */
  /* margin-top: 10px; */
  border-radius: 12px;
  background-color: rgba(255, 220, 220, 0.5);
  padding: 10px;
  padding-left: 27px !important;
}

.box2 {
  /* margin-top: 10px; */
  border-radius: 12px;
  background-color: rgba(154, 220, 181, 0.5);
  padding: 10px;
  padding-left: 27px !important;
}


.box3 {
  background: rgba(0, 136, 213, 1);
  margin-top: 10px;
  border-radius: 10px;
  padding: 10px;
  color: var(--white);
  text-align: center;
}

.imageicon {
  width: 79px;
  height: 79px;
  gap: 0px;
  border-radius: 39.5px;
  display: grid;
  place-items: center;
  background-color: rgba(255, 255, 255, 1);
  font-size: 30px;
  color: rgba(242, 2, 2, 1);

  /* opacity: 0px; */

}


.addprinthead {
  /* background: rgba(38, 50, 56, 1); */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  /* color: rgba(255, 255, 255, 1); */
  border-radius: 11px 12px 0px 0px;
  border-bottom: #000 solid 1px;
}

.dialogInner .addprinthead h1 {
  font-size: var(--font24);
  font-weight: 700;
  margin: 0;
}

.dialogViewInner .addprinthead h1 {
  font-size: var(--font24);
  font-weight: 700;
  margin: 0;
}

.dialogViewInner {
  width: min(70%, 90%);
  background: #fff;
  border-radius: 13px
}

.dialogViewInner .closebtn {
  background: none;
  border: 0;
  outline: none;
}

.dialogViewInner .dialogbody {
  padding: 20px 25px;
  max-height: 79vh;
  overflow: auto;
  margin-bottom: 25px;
}

.details h5 {
  font-size: var(--font20);
  font-weight: 650;
  margin: 15px 0px 20px 0px;
}

.box1 h2 {
  font-weight: 700;
  margin-top: 8px;
}

.box1 p {
  font-weight: 500
}

.box2 h2 {
  font-weight: 700;
  margin-top: 8px;
}

.box2 p {
  font-weight: 500;
}


.dialogDeleteInner {
  width: min(40%, 90%);
  background: #fff;
  border-radius: 13px;
  text-align: center;
}

.dialogDeleteInner .dialogbody {
  padding: 20px 30px;
  max-height: 79vh;
  overflow: auto;
  margin-bottom: 25px;
}

.dialogDeleteInner .dialogbody h4 {
  font-size: var(--font22);
  font-weight: 900;
}

.dialogDeleteInner .dialogbody p {
  font-size: var(--font18);
  font-weight: 450;
}

.deleteimg {
  text-align: center;
  margin: 20px;

  svg {
    overflow: hidden;
    width: 74px;
    fill: var(--primary);
  }
}

.deletebtn {
  text-align: center;
  margin: 17px 0px;

  button {
    margin: 0 5px;
    padding: 6px;
    width: 27%
  }
}

.product-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
}

.product-list li {
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 10px;
  padding: 5px 10px;
  font-size: var(--font14);
  background-color: var(--lightGreaan) !important;
  color: var(--gray1);
  border-radius: 5px;
  white-space: nowrap;
  font-weight: 500;
}


.printbox1 {
  /* width: 415px; */
  /* margin-top: 10px; */
  border-radius: 12px;
  background: rgba(199, 235, 255, 0.4);
  padding: 20px;
  height: 100%;
  /* padding-left:20px !important; */
}

.printbox2 {
  /* margin-top: 10px; */
  border-radius: 12px;
  background: rgba(255, 210, 210, 0.4);
  padding: 20px;
  height: 100%;
  /* padding-left: 20px !important; */
}

.printbox3 {
  /* margin-top: 10px; */
  border-radius: 12px;
  background: rgba(151, 216, 176, 0.4);
  padding: 20px;
  height: 100%;
  /* padding-left: 20px !important; */
}

.printbox4 {
  /* margin-top: 10px; */
  border-radius: 12px;
  background: rgba(255, 223, 194, 0.4);
  height: 100%;

  padding: 20px;
  /* padding-left: 20px !important; */
}

.imageicon {
  width: 75px;
  height: 75px;
  gap: 0px;
  border-radius: 39.5px;
  display: grid;
  place-items: center;
  background-color: rgba(255, 255, 255, 1);
  font-size: 30px;
}

.highlighthead {
  font-weight: 700;
  margin: 5px 0px;
}

.highlightP {
  font-weight: 500;
  margin-bottom: 0 !important;
}

.printCustomBox {
  width: 12% !important;
}

.custom-width {
  width: 74% !important;
}

.custom-date-picker {
  /* padding: 0px !important; */
  /* border-style: none !important; */
  box-shadow: 0 0 8px #00000012 !important;
  border-radius: 28px !important;
  border: 0;
  padding: 10px 45px 10px 20px !important;
}



.progress-tracker {
  display: flex;
  /* align-items: center;  */
  justify-content: space-between;
  margin: 20px 0;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 11%;
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ccc;
  border: 5px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.completed .circle {
  background-color: #28a745;
  border-color: #28a745;
}

.active .circle {
  border-color: #28a745;
  border-width: 1px;
}

.label {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
  text-align: center;
  white-space: nowrap;
  /* Prevent text wrapping */
}

.date {
  font-size: 12px;
  color: #888;
  text-align: center;
  white-space: nowrap;
  /* Prevent date wrapping */
}

.line {
  height: 5px;
  border-radius: 9px;
  background-color: #ccc;
  flex-grow: 1;
  margin: 1.2% -3%;
  /* Adjust margin to align lines with circles */
}

.completed+.line {
  background-color: #28a745;
  /* Green line */
}

.imagetick {
  width: 100%;
  /* Ensure the image fills the circle */
}

/* Adjust last line not to extend beyond the last circle */
.progress-tracker .line:last-child {
  flex-grow: 0;
}

.colorforfield {
  background: rgba(227, 244, 254, 1);
  color: rgba(0, 125, 194, 1);
  border-radius: 2px !important;
  border-color: rgba(227, 244, 254, 1);
}

.paymentbox1 {
  /* width: 415px; */
  /* margin-top: 10px; */
  color: rgba(99, 99, 99, 1);
  border-radius: 12px;
  background: rgba(238, 235, 235, 1);

  padding: 20px;
  /* padding-left:20px !important; */
}

.paymentbox2 {
  /* margin-top: 10px; */
  border-radius: 12px;
  background: rgba(52, 167, 81, 0.2);
  color: rgba(52, 167, 81, 1);

  padding: 20px;
  /* padding-left: 20px !important; */
}

.paymentbox3 {
  /* margin-top: 10px; */
  border-radius: 12px;
  background: rgba(242, 2, 2, 0.2);
  color: rgba(246, 44, 0, 1);

  padding: 20px;
  /* padding-left: 20px !important; */
}

.paymentbox4 {
  /* margin-top: 10px; */
  border-radius: 12px;
  background: rgba(255, 181, 115, 0.2);
  color: rgba(255, 181, 115, 1);



  padding: 20px;
  /* padding-left: 20px !important; */
}

.CanvasSize{width: 100%; background-color: var(--lightGreaan) !important;  border-radius: 5px;
  border: 0;
  padding: 12px;}
.CanvasSize ul{list-style: none; padding: 0; margin: 0; display: flex; align-items: center; gap: 10px; row-gap: 10px;}
.CanvasSize ul li{ background: #fff;
  border-radius: 5px;
  border: 0;
  padding: 12px;
  color: var(--gray1);
  font-size: var(--font14);
  font-weight: 500;}

.success-button {

  background-color: rgba(15, 186, 83, 1);
}
.success-button:hover{
color:rgba(15, 186, 83) !important;
  border:1px solid rgba(15, 186, 83, 1);
}

.dialogRejectView .mainPara {
  margin: 0% 11%;
}

.dialogRejectView .mainPara h4 {
  font-size: var(--font24);
  font-weight: 700;
  margin-bottom: 20px;
}

.dialogRejectView .mainPara textarea {
  height: 20vh;
  margin-bottom: 10px;
  background: rgba(242, 242, 242, 1);
  width: 75%;
  border: none;
  padding: 2%;
}

.dialogRejectView .mainPara p {
  margin-bottom: 20px;

}

.dialogRejectView {
  width: 62%;
  height: 88vh;
  background: #fff;
  border-radius: 5px;
  text-align: center;
}


.dialogRejectView .dialogbody {
  padding: 5px 0px;
  /* max-height: 79vh; */
  overflow: auto;
  /* margin-bottom: 20px; */
}

.confirmbutton {
  text-align: center;
  margin: 10px 0px;
  font-size: var(--font20) !important;
  padding: 0 10px;

  button {
    margin: 0 5px;
    padding: 6px;
    width: 15%
  }
}

.dialogRejectView .acceptbody {
  padding: 60px 0px !important;
}

.exportimage {
  margin-bottom: 5px;
}